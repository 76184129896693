import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActions, InputAdornment, TextField} from '@mui/material';
import {useState} from 'react';
import Web3 from 'web3';

export default function UnitConverter() {
    const [wei, setWei] = useState("");
    const [kWei, setKWei] = useState("");
    const [mWei, setMWei] = useState("");
    const [gWei, setGWei] = useState("");
    const [szabo, setSzabo] = useState("");
    const [finney, setFinney] = useState("");
    const [ether, setEther] = useState("");
    const [kEther, setKEther] = useState("");
    const [mEther, setMEther] = useState("");
    const [gEther, setGEther] = useState("");
    const [tEther, setTEther] = useState("");

    function updateResult(input: string, inputUnit: string) {
        if (!input) {
            setWei("");
            setKWei("");
            setMWei("");
            setGWei("");
            setSzabo("");
            setFinney("");
            setEther("");
            setKEther("");
            setMEther("");
            setGEther("");
            setTEther("");
            return;
        }

        let wei: string;
        try {
            wei = Web3.utils.toWei(input, inputUnit as any);
        } catch (error) {
            if (inputUnit !== 'wei') setWei("");
            if (inputUnit !== 'kwei') setKWei("");
            if (inputUnit !== 'mwei') setMWei("");
            if (inputUnit !== 'gwei') setGWei("");
            if (inputUnit !== 'szabo') setSzabo("");
            if (inputUnit !== 'finney') setFinney("");
            if (inputUnit !== 'ether') setEther("");
            if (inputUnit !== 'kether') setKEther("");
            if (inputUnit !== 'mether') setMEther("");
            if (inputUnit !== 'gether') setGEther("");
            if (inputUnit !== 'tether') setTEther("");
            return;
        }

        if (!wei || wei === "0") {
            if (inputUnit !== 'wei') setWei("0");
            if (inputUnit !== 'kwei') setKWei("0");
            if (inputUnit !== 'mwei') setMWei("0");
            if (inputUnit !== 'gwei') setGWei("0");
            if (inputUnit !== 'szabo') setSzabo("0");
            if (inputUnit !== 'finney') setFinney("0");
            if (inputUnit !== 'ether') setEther("0");
            if (inputUnit !== 'kether') setKEther("0");
            if (inputUnit !== 'mether') setMEther("0");
            if (inputUnit !== 'gether') setGEther("0");
            if (inputUnit !== 'tether') setTEther("0");
            return;
        }

        if (inputUnit !== 'wei') setWei(Web3.utils.fromWei(wei, 'wei'));
        if (inputUnit !== 'kwei') setKWei(Web3.utils.fromWei(wei, 'kwei'));
        if (inputUnit !== 'mwei') setMWei(Web3.utils.fromWei(wei, 'mwei'));
        if (inputUnit !== 'gwei') setGWei(Web3.utils.fromWei(wei, 'gwei'));
        if (inputUnit !== 'szabo') setSzabo(Web3.utils.fromWei(wei, 'szabo'));
        if (inputUnit !== 'finney') setFinney(Web3.utils.fromWei(wei, 'finney'));
        if (inputUnit !== 'ether') setEther(Web3.utils.fromWei(wei, 'ether'));
        if (inputUnit !== 'kether') setKEther(Web3.utils.fromWei(wei, 'kether'));
        if (inputUnit !== 'mether') setMEther(Web3.utils.fromWei(wei, 'mether'));
        if (inputUnit !== 'gether') setGEther(Web3.utils.fromWei(wei, 'gether'));
        if (inputUnit !== 'tether') setTEther(Web3.utils.fromWei(wei, 'tether'));
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 2,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Unit Converter
                </Typography>

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">Wei</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setWei(input);
                               updateResult(input, 'wei');
                           }}
                           value={wei}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">KWei</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setKWei(input);
                               updateResult(input, 'kwei');
                           }}
                           value={kWei}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">MWei</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setMWei(input);
                               updateResult(input, 'mwei');
                           }}
                           value={mWei}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">GWei</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setGWei(input);
                               updateResult(input, 'gwei');
                           }}
                           value={gWei}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">Szabo</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setSzabo(input);
                               updateResult(input, 'szabo');
                           }}
                           value={szabo}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">Finney</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setFinney(input);
                               updateResult(input, 'finney');
                           }}
                           value={finney}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">Ether</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setEther(input);
                               updateResult(input, 'ether');
                           }}
                           value={ether}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">KEther</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setKEther(input);
                               updateResult(input, 'kether');
                           }}
                           value={kEther}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">MEther</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setMEther(input);
                               updateResult(input, 'mether');
                           }}
                           value={mEther}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">GEther</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setGEther(input);
                               updateResult(input, 'gether');
                           }}
                           value={gEther}
                           margin="dense"
                />

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           InputProps={{
                               endAdornment: <InputAdornment position="end">TEther</InputAdornment>,
                           }}
                           onChange={(e) => {
                               let input = e.target.value.replace(/[^0-9.]/g, '');
                               const firstDotIndex = input.indexOf('.');
                               if (firstDotIndex !== -1) {
                                   input = input.substring(0, firstDotIndex + 1) + input.substring(firstDotIndex + 1).replace(/\./g, '');
                               }
                               setTEther(input);
                               updateResult(input, 'tether');
                           }}
                           value={tEther}
                           margin="dense"
                />
            </CardContent>

            <CardActions></CardActions>
        </Card>
    );
}