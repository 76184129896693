import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Autocomplete, CardActions, Grid, TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useState} from 'react';
import Web3 from 'web3';
import {isHexPrefixed} from 'web3-validator';
import {getRpcs} from './types';

const rpcs = getRpcs();

function Call() {
    const [rpc, setRPC] = useState("")
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [value, setValue] = useState("");
    const [data, setData] = useState("");
    const [gasPrice, setGasPrice] = useState("");
    const [gasLimit, setGasLimit] = useState("");
    const [blockNumber, setBlockNumber] = useState("");

    const [callButtonDisabled, setCallButtonDisabled] = useState(true);
    const [callButtonLoading, setCallButtonLoading] = useState(false);

    const [gasUsed, setGasUsed] = useState("");
    const [returnValue, setReturnValue] = useState("");

    async function handleCall() {
        setCallButtonLoading(true);

        let parsedValue: string = "";
        if (value) {
            try {
                parsedValue = Web3.utils.toWei(value, 'ether');
            } catch (error) {
                setCallButtonLoading(false);
                alert('Failed to parse value: ' + (error as Error).message);
                return;
            }
        }

        let parsedGasPrice: string = "";
        if (gasPrice) {
            try {
                parsedGasPrice = Web3.utils.toWei(gasPrice, 'gwei');
            } catch (error) {
                setCallButtonLoading(false);
                alert('Failed to parse gasPrice: ' + (error as Error).message);
                return;
            }
        }

        let parsedData: string = data;
        if (!isHexPrefixed(parsedData)) parsedData = '0x' + parsedData;

        let transaction = {
            from: from || '0x0000000000000000000000000000000000000000',
            to: to || null,
            value: parsedValue || null,
            data: parsedData,
            gasPrice: parsedGasPrice || null,
            gas: gasLimit || null,
        };

        let gasUsed;
        let result;
        executeScope: {
            try {
                // @ts-ignore
                gasUsed = (await new Web3(rpc).eth.estimateGas(transaction, blockNumber ? blockNumber : undefined)).toLocaleString('en', {useGrouping: false})
            } catch (error) {
                alert('Failed to estimate gas: ' + (error as Error).message);
                break executeScope;
            }

            try {
                // @ts-ignore
                result = (await new Web3(rpc).eth.call(transaction, blockNumber ? blockNumber : undefined));
            } catch (error) {
                alert('Failed to call: ' + (error as Error).message);
                break executeScope;
            }

            break executeScope;
        }
        if (gasUsed && result) {
            setGasUsed(gasUsed);
            setReturnValue(result);
        } else {
            setGasUsed("");
            setReturnValue("");
        }

        setCallButtonLoading(false);
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Call
                </Typography>

                <Autocomplete
                    sx={{
                        userSelect: 'none',
                    }}
                    spellCheck="false"
                    freeSolo
                    size="small"
                    options={rpcs}
                    renderInput={
                        (params) =>
                            <TextField
                                spellCheck="false"
                                margin="normal"
                                {...params}
                                label="RPC"
                                onChange={(e) => {
                                    setRPC(e.target.value);
                                    setCallButtonDisabled(e.target.value.length == 0);
                                }}
                                value={rpc}
                            />
                    }
                    onInputChange={(_e, v) => {
                        setRPC(v);
                        setCallButtonDisabled(v.length == 0);
                    }}
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="From"
                           onChange={(e) => setFrom(e.target.value)}
                           value={from}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="To"
                           onChange={(e) => setTo(e.target.value)}
                           value={to}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Value(Ether)"
                           onChange={(e) => setValue(e.target.value)}
                           value={value}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Data"
                           multiline
                           rows={6}
                           onChange={
                               (e) => {
                                   let input = e.target.value.replace(/[\s\n\r]+/g, '');
                                   if (isHexPrefixed(input)) input = input.substring(2);
                                   setData(input);
                               }
                           }
                           value={data}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="GasPrice(GWei)"
                           onChange={(e) => setGasPrice(e.target.value)}
                           value={gasPrice}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="GasLimit"
                           onChange={(e) => setGasLimit(e.target.value)}
                           value={gasLimit}
                           margin="normal"
                />

                <TextField sx={{
                    mt: 1,
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="BlockNumber"
                           placeholder="latest"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           onChange={(e) => setBlockNumber(e.target.value)}
                           value={blockNumber}
                           margin="normal"
                />

                <Grid container justifyContent="flex-end">
                    <LoadingButton sx={{
                        mt: 1,
                        mb: 1,
                    }}
                                   size="small"
                                   variant="contained"
                                   disabled={callButtonDisabled}
                                   onClick={handleCall}
                                   loading={callButtonLoading}
                    >Call</LoadingButton>
                </Grid>

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="GasUsed"
                           value={gasUsed}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           label="ReturnValue"
                           multiline
                           rows={6}
                           value={returnValue}
                           onChange={(e) => setReturnValue(e.target.value)}
                           margin="normal"
                />
            </CardContent>

            <CardActions sx={{
                pb: 0.5,
            }}></CardActions>
        </Card>
    );
}

export default Call;