import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActions, TextField} from '@mui/material';
import {useState} from 'react';
import {isHexPrefixed} from 'web3-validator';

function ReturnValueParser() {
    const [input, setInput] = useState("");
    const [parsed, setParsed] = useState("");

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let input = e.target.value.replace(/[\s\n\r]+/g, '');
        if (isHexPrefixed(input)) input = input.substring(2);
        setInput(input);

        let parsed = input;
        if (parsed.length > 64) {
            parsed = parsed.match(new RegExp('.{1,' + 64 + '}', 'g'))?.join('\n') ?? '';
        }
        setParsed(parsed);
    }

    function handleParsedChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let parsed = e.target.value;
        if (isHexPrefixed(parsed)) parsed = parsed.substring(2);

        setParsed(parsed);
        setInput(parsed.replace(/[\r\n]+/g, ''));
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Return Value Parser
                </Typography>

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           label="Input"
                           multiline
                           rows={6}
                           onChange={(e) => handleInputChange(e)}
                           value={input}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           label="Parsed"
                           multiline
                           rows={12}
                           onChange={(e) => handleParsedChange(e)}
                           value={parsed}
                           margin="normal"
                />
            </CardContent>

            <CardActions sx={{
                pb: 0.5,
            }}></CardActions>
        </Card>
    );
}

export default ReturnValueParser;