import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Autocomplete, CardActions, Grid, TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useState} from 'react';
import Web3 from 'web3';
import {getRpcs} from './types';

const rpcs = getRpcs();

function GetStorage() {
    const [rpc, setRPC] = useState("");
    const [slot, setSlot] = useState("");
    const [contract, setContract] = useState("");
    const [blockNumber, setBlockNumber] = useState("");

    const [getButtonDisabled, setGetButtonDisabled] = useState(true);
    const [getButtonLoading, setGetButtonLoading] = useState(false);

    const [content, setContent] = useState("");

    async function handleGet() {
        setGetButtonLoading(true);

        try {
            setContent(await new Web3(rpc).eth.getStorageAt(contract, slot, blockNumber ? blockNumber : undefined));
        } catch (error) {
            alert('Failed to get storage: ' + (error as Error).message);
        }

        setGetButtonLoading(false);
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}
                >
                    Get Storage
                </Typography>

                <Autocomplete
                    sx={{
                        userSelect: 'none',
                    }}
                    spellCheck="false"
                    freeSolo
                    size="small"
                    options={rpcs}
                    renderInput={
                        (params) =>
                            <TextField
                                spellCheck="false"
                                margin="normal"
                                {...params}
                                label="RPC"
                                onChange={(e) => {
                                    setRPC(e.target.value);
                                    setGetButtonDisabled(e.target.value.length == 0 || contract.length == 0 || slot.length == 0);
                                }}
                                value={rpc}
                            />
                    }
                    onInputChange={(_e, v) => {
                        setRPC(v);
                        setGetButtonDisabled(v.length == 0 || contract.length == 0 || slot.length == 0);
                    }}
                />

                <TextField sx={{
                    mt: 1,
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Contract"
                           onChange={(e) => {
                               setContract(e.target.value);
                               setGetButtonDisabled(rpc.length == 0 || e.target.value.length == 0 || slot.length == 0);
                           }}
                           value={contract}
                           margin="normal"

                />

                <TextField sx={{
                    mt: 1,
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Slot"
                           onChange={(e) => {
                               setSlot(e.target.value);
                               setGetButtonDisabled(rpc.length == 0 || contract.length == 0 || e.target.value.length == 0);
                           }}
                           value={slot}
                           margin="normal"
                />

                <TextField sx={{
                    mt: 1,
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="BlockNumber"
                           placeholder="latest"
                           InputLabelProps={{
                               shrink: true,
                           }}
                           onChange={(e) => setBlockNumber(e.target.value)}
                           value={blockNumber}
                           margin="normal"
                />


                <Grid container justifyContent="flex-end">
                    <LoadingButton
                        sx={{
                            mt: 1,
                            mb: 1,
                        }}
                        size="small"
                        variant="contained"
                        disabled={getButtonDisabled}
                        onClick={handleGet}
                        loading={getButtonLoading}
                    >Get</LoadingButton>
                </Grid>


                <TextField sx={{
                    mt: 1,
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           label="Content"
                           multiline
                           rows={2}
                           value={content}
                           onChange={(e) => setContent(e.target.value)}
                           margin="normal"
                />
            </CardContent>

            <CardActions sx={{
                pb: 0.5,
            }}></CardActions>
        </Card>
    );
}

export default GetStorage;