import FormatConverter from './FormatConverter';
import Grid from '@mui/material/Unstable_Grid2';
import GetStorage from './GetStorage';
import {AppBar, Button, Toolbar, Typography} from '@mui/material';
import UnitConverter from './UnitConverter';
import CustomTransaction from './CustomTransaction';
import {Masonry} from '@mui/lab';
import Call from './Call';
import Keccak256 from './Keccak256';
import CalldataParser from './CalldataParser';
import ReturnValueParser from "./ReturnValueParser";

function EVMTools() {
    return (
        <>
            <AppBar>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{
                        userSelect: 'none',
                        flexGrow: 1,
                        pl: 1,
                    }}>
                        EVMTools
                    </Typography>
                    <Button color="inherit"
                            onClick={() => {
                                window.open('https://www.4byte.directory/')
                            }}
                    >4byte</Button>
                    <Button color="inherit"
                            onClick={() => {
                                window.open('https://app.dedaub.com/decompile')
                            }}
                    >Dedaub</Button>
                </Toolbar>
            </AppBar>
            <Toolbar/>

            <Grid container
                  maxWidth="xl"
                  sx={{
                      maxWidth: '1700px',
                      width: '100%',
                  }}
                  margin="auto"
                  spacing={2}
            >
                <Grid>
                    <Masonry
                        spacing={2}
                        columns={{xs: 1, sm: 2, md: 2, lg: 3, xl: 4}}
                        sx={{
                            ml: 0,
                        }}
                    >
                        <GetStorage></GetStorage>
                        <Keccak256></Keccak256>
                        <Call></Call>
                        <CustomTransaction></CustomTransaction>
                        <UnitConverter></UnitConverter>
                        <FormatConverter></FormatConverter>
                        <CalldataParser></CalldataParser>
                        <ReturnValueParser></ReturnValueParser>
                    </Masonry>
                </Grid>
            </Grid>
        </>
    );
}

export default EVMTools;
