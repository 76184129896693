import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActions, TextField} from '@mui/material';
import {useState} from 'react';
import Web3 from 'web3';

function Keccak256() {
    const [input, setInput] = useState("");
    const [hash, setHash] = useState("");
    const [selector, setSelector] = useState("");

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (!e.target.value) {
            setInput("");
            setHash("");
            setSelector("");
            return;
        }

        try {
            setInput(e.target.value);
            let keccak256 = Web3.utils.keccak256(e.target.value).substring(2);
            setHash(keccak256);
            setSelector(keccak256.substring(0, 8));
        } catch (_) {
            setHash("");
            setSelector("");
        }
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Keccak256
                </Typography>

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           label="Input"
                           multiline
                           rows={4}
                           onChange={(e) => handleInputChange(e)}
                           value={input}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           label="Hash"
                           multiline
                           rows={2}
                           value={hash}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           size="small"
                           label="Selector"
                           value={selector}
                           margin="normal"
                />
            </CardContent>

            <CardActions sx={{
                pb: 0.5,
            }}></CardActions>
        </Card>
    );
}

export default Keccak256;