import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {Autocomplete, CardActions, Grid, TextField} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {useState} from 'react';
import Web3 from 'web3';
import {isHexPrefixed} from 'web3-validator';

let web3: Web3 | null = null;

function CustomTransaction() {
    const [accountsDisabled, setAccountsDisabled] = useState(true);
    const [accounts, setAccounts] = useState<string[]>([]);
    const [selectedAccount, setSelectedAccount] = React.useState("");

    const [connectButtonContent, setConnectButtonContent] = useState("Connect");
    const [connectButtonDisabled, setConnectButtonDisabled] = useState(false);
    const [connectButtonLoading, setConnectButtonLoading] = useState(false);

    const [to, setTo] = useState("");
    const [value, setValue] = useState("");
    const [data, setData] = useState("");
    const [gasPrice, setGasPrice] = useState("");
    const [gasLimit, setGasLimit] = useState("");

    const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
    const [sendButtonLoading, setSendButtonLoading] = useState(false);

    const [estimateGasButtonDisabled, setEstimateGasButtonDisabled] = useState(true);
    const [estimateGasButtonLoading, setEstimateGasButtonLoading] = useState(false);

    React.useEffect(() => {
        if ('ethereum' in window) {
            // @ts-ignore
            web3 = new Web3(window.ethereum);
        } else {
            alert('No web3 wallet is available');
            return;
        }

        const handleAccountsChanged = async () => {
            await updateAccounts();
        };

        if (web3.currentProvider) {
            web3.currentProvider.on('accountsChanged', handleAccountsChanged);
        }

        return () => {
            if (web3?.currentProvider) {
                web3?.currentProvider.removeListener('accountsChanged', handleAccountsChanged);
            }
        };
    }, [])

    async function updateAccounts() {
        if (!web3) {
            alert('No web3 wallet is available');
            return;
        }

        let accounts = await web3.eth.getAccounts()
        setAccounts(accounts);
        setSelectedAccount(accounts[0]);
        if (accounts.length) {
            setAccountsDisabled(false);
            setConnectButtonContent("Connected");
            setConnectButtonDisabled(true);
            setEstimateGasButtonDisabled(false);
            setSendButtonDisabled(false);
        } else {
            setAccountsDisabled(true);
            setConnectButtonContent("Connect");
            setConnectButtonDisabled(false);
            setEstimateGasButtonDisabled(true);
            setSendButtonDisabled(true);
        }
    }

    async function handleConnect() {
        if (!web3) {
            alert('No web3 wallet is available');
            return;
        }

        setConnectButtonLoading(true);

        try {
            await web3.currentProvider?.request({method: 'eth_requestAccounts'});
            await updateAccounts();
        } catch (error) {
            alert('Failed to get accounts: ' + (error as Error).message);
        }

        setConnectButtonLoading(false);
    }

    async function handleEstimateGas() {
        if (!web3) {
            alert('No web3 wallet is available');
            return;
        }

        setEstimateGasButtonLoading(true);

        let parsedGasPrice: string = "";
        if (gasPrice) {
            try {
                parsedGasPrice = Web3.utils.toWei(gasPrice, 'gwei');
            } catch (error) {
                setEstimateGasButtonLoading(false);
                alert('Failed to parse gasPrice: ' + (error as Error).message);
                return;
            }
        }

        let parsedValue: string = "";
        if (value) {
            try {
                parsedValue = Web3.utils.toWei(value, 'ether');
            } catch (error) {
                setEstimateGasButtonLoading(false);
                alert('Failed to parse value: ' + (error as Error).message);
                return;
            }
        }

        let parsedData: string = data;
        if (!isHexPrefixed(parsedData)) parsedData = '0x' + parsedData;

        let transaction = {
            from: selectedAccount,
            to: to || null,
            value: parsedValue || null,
            data: parsedData,
            gasPrice: parsedGasPrice || null,
        };

        try {
            // @ts-ignore
            setGasLimit((await web3.eth.estimateGas(transaction)).toLocaleString('en', {useGrouping: false}))
        } catch (error) {
            setGasLimit("");
            alert('Failed to estimate gas: ' + (error as Error).message);
        }

        setEstimateGasButtonLoading(false);
    }

    async function handleSend() {
        if (!web3) {
            alert('No web3 wallet is available');
            return;
        }

        setSendButtonLoading(true);

        let parsedGasPrice: string = "";
        if (gasPrice) {
            try {
                parsedGasPrice = Web3.utils.toWei(gasPrice, 'gwei');
            } catch (error) {
                setSendButtonLoading(false);
                alert('Failed to parse gasPrice: ' + (error as Error).message);
                return;
            }
        }

        let parsedValue: string = "";
        if (value) {
            try {
                parsedValue = Web3.utils.toWei(value, 'ether');
            } catch (error) {
                setSendButtonLoading(false);
                alert('Failed to parse value: ' + (error as Error).message);
                return;
            }
        }

        let parsedData: string = data;
        if (!isHexPrefixed(parsedData)) parsedData = '0x' + parsedData;

        try {
            const txHash = await web3?.currentProvider?.request(
                {
                    method: "eth_sendTransaction",
                    params: [
                        {
                            gasPrice: parsedGasPrice ? Web3.utils.numberToHex(parsedGasPrice) : null,
                            gas: gasLimit ? Web3.utils.numberToHex(gasLimit) : null,
                            from: selectedAccount,
                            to: to || null,
                            value: parsedValue ? Web3.utils.numberToHex(parsedValue) : null,
                            data: parsedData,
                        }
                    ]
                }
            )
            alert('Transaction has been sent: ' + txHash)
        } catch (error) {
            alert('Failed to send transaction: ' + (error as Error).message);
        }

        setSendButtonLoading(false);
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Custom Transaction
                </Typography>

                <Autocomplete sx={{
                    userSelect: 'none',
                }}
                              spellCheck="false"
                              fullWidth
                              size="small"
                              disableClearable
                              value={selectedAccount}
                              onChange={(_e, v: string | null) => {
                                  if (v) setSelectedAccount(v);
                              }}
                              options={accounts}
                              disabled={accountsDisabled}
                              renderInput={(params) => <TextField spellCheck="false" margin="normal" {...params}
                                                                  label="Account"/>}
                />

                <Grid container justifyContent="flex-end">
                    <LoadingButton sx={{
                        mt: 1,
                        mb: 1,
                    }}
                                   spellCheck="false"
                                   size="small"
                                   variant="contained"
                                   disabled={connectButtonDisabled}
                                   onClick={handleConnect}
                                   loading={connectButtonLoading}
                    >{connectButtonContent}</LoadingButton>
                </Grid>

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="To"
                           onChange={(e) => setTo(e.target.value)}
                           value={to}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Value(Ether)"
                           onChange={(e) => setValue(e.target.value)}
                           value={value}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="Data"
                           multiline
                           rows={6}
                           onChange={
                               (e) => {
                                   let input = e.target.value.replace(/[\s\n\r]+/g, '');
                                   if (isHexPrefixed(input)) input = input.substring(2);
                                   setData(input);
                               }
                           }
                           value={data}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="GasLimit"
                           onChange={(e) => setGasLimit(e.target.value)}
                           value={gasLimit}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           size="small"
                           fullWidth
                           label="GasPrice(GWei)"
                           onChange={(e) => setGasPrice(e.target.value)}
                           value={gasPrice}
                           margin="normal"
                />

                <Grid container justifyContent="flex-end">
                    <LoadingButton sx={{
                        mt: 1,
                        mb: 1,
                        mr: 1,
                    }}
                                   size="small"
                                   variant="contained"
                                   disabled={estimateGasButtonDisabled}
                                   onClick={handleEstimateGas}
                                   loading={estimateGasButtonLoading}
                    >Estimate Gas</LoadingButton>

                    <LoadingButton sx={{
                        userSelect: 'none',
                        mt: 1,
                        mb: 1,
                    }}
                                   size="small"
                                   variant="contained"
                                   disabled={sendButtonDisabled}
                                   onClick={handleSend}
                                   loading={sendButtonLoading}
                    >Send</LoadingButton>
                </Grid>
            </CardContent>

            <CardActions sx={{
                pb: 0,
            }}></CardActions>
        </Card>
    );
}

export default CustomTransaction;