import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {CardActions, TextField} from '@mui/material';
import {useState} from 'react';
import {isHex} from 'web3-validator';
import {isHexPrefixed} from 'web3-validator';
import BN from 'bn.js';
import Web3 from 'web3';

function FormatConverter() {
    const [hex, setHex] = useState("");
    const [decimal, setDecimal] = useState("");
    const [utf8, setUTF8] = useState("");

    function handleHexChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let value: string = e.target.value;
        setHex(value);

        if (isHex(value)) {
            if (isHexPrefixed(value)) value = value.substring(2);
            setDecimal(new BN(value, "hex").toLocaleString());

            try {
                setUTF8(Web3.utils.hexToUtf8(value));
            } catch (_error) {
                return;
            }
        } else {
            setDecimal("");
            setUTF8("");
        }
    }

    function handleDecimalChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setDecimal(e.target.value);

        if (/^\d+$/.test(e.target.value)) {
            let hex = new BN(e.target.value).toJSON();
            setHex(hex);

            try {
                setUTF8(Web3.utils.hexToUtf8(hex))
            } catch (_error) {
                return;
            }
        } else {
            setHex("");
            setUTF8("");
        }
    }

    function handleUTF8Change(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setUTF8(e.target.value)

        let hex: string = "";
        try {
            hex = Web3.utils.fromUtf8(e.target.value).substring(2);
        } catch (_error) {
        }
        setHex(hex);
        if (hex) {
            setDecimal(new BN(hex, "hex").toLocaleString());
        } else {
            setDecimal("");
        }
    }

    return (
        <Card>
            <CardContent sx={{
                mb: 0,
                pb: 0,
            }}>
                <Typography variant="h5" component="div" sx={{
                    userSelect: 'none',
                    pl: 1,
                    pb: 1,
                }}
                            onDragStart={(e) => {
                                e.preventDefault();
                            }}>
                    Format Converter
                </Typography>

                <TextField sx={{
                    userSelect: 'none',
                }}
                           spellCheck="false"
                           fullWidth
                           label="Hex"
                           multiline
                           rows={6}
                           onChange={(e) => handleHexChange(e)}
                           value={hex}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           label="Decimal"
                           multiline
                           rows={6}
                           onChange={(e) => handleDecimalChange(e)}
                           value={decimal}
                           margin="normal"
                />

                <TextField sx={{
                    userSelect: 'none',
                    mt: 1,
                }}
                           spellCheck="false"
                           fullWidth
                           label="UTF8"
                           multiline
                           rows={6}
                           onChange={(e) => handleUTF8Change(e)}
                           value={utf8}
                           margin="normal"
                />
            </CardContent>

            <CardActions sx={{
                pb: 0.5,
            }}></CardActions>
        </Card>
    );
}

export default FormatConverter;